import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Tooltip, Typography } from 'antd'

interface AnonymAccessActionsProps {
    editable: boolean
    handleDelete: (sharedFileUUID: string) => Promise<void>
    deleteLoading: boolean
    save: () => Promise<void>
    edit: () => void
    cancel: () => void
    handleCopyAccess: () => void
    editingKey: string
}

const { confirm } = Modal

const AnonymAccessActions = ({
    editable,
    handleDelete,
    deleteLoading,
    save,
    edit,
    cancel,
    handleCopyAccess,
    editingKey,
}: AnonymAccessActionsProps) => {
    const handleDeleteButtonClick = () => {
        confirm({
            title: 'Підтвердити видалення',
            okText: 'Видалити',
            okType: 'danger',
            cancelText: 'Скасувати',
            onOk: handleDelete,
        })
    }

    return editable ? (
        <span>
            <Typography.Link
                onClick={save}
                style={{ marginRight: 8 }}
            >
                Зберегти
            </Typography.Link>
            <Popconfirm
                title='Sure to cancel?'
                onConfirm={cancel}
            >
                <a>Відмінити</a>
            </Popconfirm>
        </span>
    ) : (
        <div style={{ display: 'flex', gap: 15, justifyContent: 'center' }}>
            <Tooltip title='Скопіювати посилання'>
                <Button
                    size='middle'
                    icon={<CopyOutlined />}
                    onClick={handleCopyAccess}
                />
            </Tooltip>
            <Tooltip title='Редагувати примітку'>
                <Button
                    size='middle'
                    icon={<EditOutlined />}
                    disabled={editingKey !== ''}
                    onClick={edit}
                    loading={deleteLoading}
                />
            </Tooltip>
            <Tooltip title='Видалити доступ'>
                <Button
                    size='middle'
                    icon={<DeleteOutlined />}
                    danger
                    onClick={handleDeleteButtonClick}
                    loading={deleteLoading}
                />
            </Tooltip>
        </div>
    )
}

export default AnonymAccessActions
