import { Button, Modal, Select, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useState } from 'react'
import DeleleAction from './components/DeleleAction'
import NewUserModal from './components/NewUserModal'
import useObjPermissions from '../../../../hooks/useObjPermissions'
import { AccessTypeEnum, ObjPermission, Permission, User } from '../../../../root/types'
import useAllPermissions from '../../../../hooks/useAllPermissions'

interface AccessRightsModalProps {
    open: boolean
    onCancel: () => void
    fileUUID: string
    setAccessRightsModal: (open: boolean) => void
}

const { Option } = Select

const AccessRightsModal = ({
    open,
    onCancel,
    fileUUID,
    setAccessRightsModal,
}: AccessRightsModalProps) => {
    const {
        objPermissions,
        objPermLoading,
        setObjPermissions,
        objPermissionsUpdate,
        updateLoading,
    } = useObjPermissions(fileUUID)

    const { permissions, permLoading } = useAllPermissions()

    const [newUserModal, setNewUserModal] = useState(false)

    //Change accessRights value for a user
    const handleAccessRightChange = (value: string, userIndex: number) => {
        const selectedPermission = permissions.find((elem) => elem.accessTypeUUID === value)
        setObjPermissions((prevUsers) => {
            const updatedUsers = [...prevUsers]
            updatedUsers[userIndex].permission = selectedPermission as Permission
            return updatedUsers
        })
    }

    const itemsColumns: ColumnsType<ObjPermission> = [
        {
            width: '50%',
            title: `Користувач`,
            dataIndex: 'user',
            key: 'user',
            render: (_: any, record: ObjPermission) => <div>{record.user.name}</div>,
        },
        {
            width: '50%',
            title: `Право`,
            dataIndex: 'permission',
            key: 'permission',
            render: (_: any, record: ObjPermission, index: number) => (
                <Select
                    style={{ width: '100%' }}
                    value={record.permission.name}
                    onChange={(value) => handleAccessRightChange(value, index)}
                    disabled={record.isOwner}
                    loading={permLoading}
                >
                    {permissions?.map((item) => (
                        <Option
                            key={item.accessTypeUUID}
                            value={item.accessTypeUUID}
                        >
                            {item.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            width: 50,
            title: '',
            key: 'action',
            render: (_: any, record: ObjPermission) => (
                <>
                    {!record.isOwner && (
                        <DeleleAction
                            objPermissions={objPermissions}
                            setObjPermissions={setObjPermissions}
                            currentObj={record}
                        />
                    )}
                </>
            ),
        },
    ]

    //Add new user with rights
    const handleUserAdd = (user: User) => {
        if (!user) {
            return
        }
        setObjPermissions((prev) => [
            ...prev,
            {
                user: { userUUID: user.userUUID, name: user.name },
                permission: {
                    accessTypeUUID: AccessTypeEnum['Перегляд'],
                    name: 'Перегляд',
                },
                isOwner: false,
            },
        ])
    }

    //Update permissions list
    const objPermissionsListEdit = () => {
        const filteredPayload = objPermissions
            .filter((elem) => !elem.isOwner)
            .map((elem) => ({
                userUUID: elem.user.userUUID,
                accessTypeUUID: elem.permission.accessTypeUUID,
            }))

        objPermissionsUpdate(fileUUID, filteredPayload)
        setAccessRightsModal(false)
    }

    return (
        <Modal
            width={600}
            open={open}
            onCancel={onCancel}
            okText={'Зберегти'}
            onOk={() => objPermissionsListEdit()}
            cancelText={'Скасувати'}
            title={<div style={{ marginBottom: 30 }}></div>}
            maskClosable={false}
            confirmLoading={updateLoading}
        >
            <>
                <Table
                    columns={itemsColumns}
                    dataSource={objPermissions}
                    loading={objPermLoading}
                    pagination={false}
                    rowKey={(item) => item.user.userUUID}
                />
                <Button
                    onClick={() => setNewUserModal(true)}
                    type='primary'
                    style={{
                        marginTop: 16,
                    }}
                >
                    Додати користувача
                </Button>
                {newUserModal && (
                    <NewUserModal
                        open={newUserModal}
                        onCancel={() => setNewUserModal(false)}
                        handleUserAdd={handleUserAdd}
                        objPermissions={objPermissions}
                    />
                )}
            </>
        </Modal>
    )
}

export default AccessRightsModal
