import makeRequest from './makeRequest'
import { apiBaseurl } from './baseurl'
import { ObjPermission } from '../root/types'

export interface UpdateObjPermPayload {
    userUUID: string
    accessTypeUUID: string
}

export const PermissionsOnObjectApi = {
    getObjPermissions: async (fileUUID: string): Promise<ObjPermission[]> => {
        const { data } = await makeRequest<ObjPermission[]>({
            url: `${apiBaseurl}/permissions/${fileUUID}/`,
        })

        return data
    },

    updateObjPermissions: async (
        fileUUID: string,
        payload: UpdateObjPermPayload[]
    ): Promise<ObjPermission[]> => {
        const { data } = await makeRequest<ObjPermission[]>({
            method: 'put',
            url: `${apiBaseurl}/permissions/${fileUUID}/`,
            data: payload,
        })

        return data
    },
}
