export enum ZeroedEnum {
    'zeroedUUID' = '00000000-0000-0000-0000-000000000000',
}

export interface User {
    userUUID: string
    name: string
}

export interface Permission {
    accessTypeUUID: string
    name: string
}

export interface ObjPermission {
    user: User
    permission: Permission
    isOwner: boolean
}

export enum AccessTypeEnum {
    'Перегляд' = 'ec3d165a-d6fc-4cd3-864f-79ba7598bade',
}
