import { useEffect, useRef, useState } from 'react'

import styles from './Files.module.scss'

import { IFile, SavePolicy } from '../../hooks/useArchive'
import { File } from '../index'
import { notification, Spin } from 'antd'

interface FileProps {
    files: IFile[] | undefined
    onRename: (fileUUID: string, destinationUUID: string, destinationName: string) => void
    onReplace: (filename: string, destination: string) => void
    setCopyFile: (file: IFile) => void
    onDelete: (fileUUID: string, name: string, isDirectory: boolean) => void
    onAdd: (file: { name: string; file: File; savePolicy: SavePolicy }[]) => Promise<void>
    currentDirectoryUUID: string
}

const Files = ({
    files = [],
    onDelete,
    onRename,
    setCopyFile,
    onReplace,
    onAdd,
    currentDirectoryUUID,
}: FileProps) => {
    const filesRef = useRef<HTMLDivElement>(null)
    const [addLoading, setAddLoading] = useState(false)

    useEffect(() => {
        if (!filesRef.current) {
            return
        }

        const body = document.body

        const uploadFileList = async (files: FileList) => {
            const filesWithoutFolders = Array.from(files).filter((file) => file.type)

            if (files.length !== filesWithoutFolders.length) {
                notification.error({ message: 'Можна перетягувати лише файли' })
            }

            setAddLoading(true)
            await onAdd(
                filesWithoutFolders.map((file) => ({ name: file.name, file, savePolicy: 'reject' }))
            )
            setAddLoading(false)
        }

        const drop = (e: DragEvent) => {
            e.stopPropagation()
            e.preventDefault()

            if (!e.dataTransfer) {
                return
            }
            const files = e.dataTransfer.files

            uploadFileList(files)
        }

        const paste = (e: ClipboardEvent) => {
            const files = e.clipboardData?.files
            if (!files) {
                return
            }

            uploadFileList(files)
        }

        body.addEventListener('paste', paste)
        body.addEventListener('drop', drop)

        return () => {
            body.removeEventListener('drop', drop)
            body.removeEventListener('paste', paste)
        }
    }, [filesRef.current])

    return (
        <div
            className={styles.files}
            ref={filesRef}
        >
            {files?.map((file) => (
                <File
                    key={file.name}
                    file={file}
                    onRename={onRename}
                    onReplace={onReplace}
                    setCopyFile={setCopyFile}
                    onDelete={onDelete}
                    currentDirectoryUUID={currentDirectoryUUID}
                />
            ))}
            {addLoading && (
                <div className={styles.loaderContainer}>
                    <Spin />
                </div>
            )}
        </div>
    )
}

export default Files
