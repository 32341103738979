import { DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { ObjPermission } from '../../../../../root/types'

const { confirm } = Modal

interface DeleleActionProps {
    objPermissions: ObjPermission[]

    setObjPermissions: (objPermissions: ObjPermission[]) => void
    currentObj: ObjPermission
}

const DeleleAction = ({ objPermissions, setObjPermissions, currentObj }: DeleleActionProps) => {
    const handleDelete = () => {
        setObjPermissions(
            objPermissions.filter((elem) => elem.user.userUUID !== currentObj.user.userUUID)
        )
    }

    const handleDeleteButtonClick = () => {
        confirm({
            title: 'Підтвердити видалення',
            okText: 'Видалити',
            okType: 'danger',
            cancelText: 'Скасувати',
            onOk: handleDelete,
        })
    }

    return (
        <Button
            size='small'
            icon={<DeleteOutlined />}
            danger
            onClick={handleDeleteButtonClick}
        />
    )
}

export default DeleleAction
