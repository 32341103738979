import makeRequest from './makeRequest'
import { apiBaseurl } from './baseurl'

export const FileApi = {
    getFile: async (fileUUID: string, thumbnail: boolean = false): Promise<Blob> => {
        const data = await makeRequest({
            url: `${apiBaseurl}/files/${fileUUID}/`,
            params: {
                thumbnail,
            },
            responseType: 'blob',
        })

        // @ts-ignore
        return data as Blob
    },
}
