import { ZeroedEnum } from '../root/types'
import { apiBaseurl } from './baseurl'
import makeRequest from './makeRequest'

export interface SharedToken {
    url: string
    sharedTokenUUID: string
    dateCreated: string
    description: string
}

export interface AnonymAccess {
    fsObjectUUID: string
    name: string
    sharedTokens: SharedToken[]
}

export const AnonymAccessApi = {
    getAnonymAccessInfo: async (fileUUID: string) => {
        const { data } = await makeRequest<AnonymAccess>({
            url: `${apiBaseurl}/shared-files-token/${fileUUID}/${ZeroedEnum['zeroedUUID']}/`,
        })
        return data
    },

    createAnonymAccess: async (fileUUID: string, comment: string): Promise<SharedToken> => {
        const { data } = await makeRequest<SharedToken>({
            method: 'post',
            url: `${apiBaseurl}/shared-files-token/${fileUUID}/${ZeroedEnum['zeroedUUID']}/`,
            data: {
                description: comment,
            },
        })

        return data
    },

    updateAnonymAccess: async (sharedTokenUUID: string, comment: string): Promise<SharedToken> => {
        const { data } = await makeRequest<SharedToken>({
            method: 'put',
            url: `${apiBaseurl}/shared-files-token/${ZeroedEnum['zeroedUUID']}/${sharedTokenUUID}/`,
            data: {
                description: comment,
            },
        })

        return data
    },

    deleteAnonymAccess: async (sharedTokenUUID: string): Promise<void> => {
        await makeRequest({
            method: 'delete',
            url: `${apiBaseurl}/shared-files-token/${ZeroedEnum['zeroedUUID']}/${sharedTokenUUID}/`,
        })
    },
}
