import { KeyboardEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Select, Space, Spin, Switch } from 'antd'
import { FileOutlined, FolderOutlined, SearchOutlined } from '@ant-design/icons'

import styles from './SearchFile.module.scss'

import { SearchApi } from '../../api/search'
import { encodeToBase64 } from '../../utils/base64'
import Thumbnail from '../Thumbnail'
import { v4 as uuidv4 } from 'uuid'
import { IFile } from '../../hooks/useArchive'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setPaths } from '../../store/rootPathsSlice'

interface SearchFileProps {
    currentDirectoryFiles: IFile[]
}

const { Option } = Select

const SearchFile = ({ currentDirectoryFiles }: SearchFileProps) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<IFile[]>([])
    const [searchType, setSearchType] = useState<'global' | 'directory'>('global')

    const routePaths = useAppSelector((state) => state.rootPathsSlice.paths)

    const handleSearch = async () => {
        try {
            setLoading(true)
            let filename = encodeToBase64(value)

            const files = await SearchApi.search(filename)

            if (searchType === 'directory') {
                const searchDirectoryFiles = files.filter((searchFile) =>
                    currentDirectoryFiles.some(
                        (curFile) => curFile.fsObjectUUID === searchFile.fsObjectUUID
                    )
                )
                setFiles(searchDirectoryFiles)
            } else setFiles(files)

            //@ts-ignore
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const handleSelect = async (filePath: string | undefined) => {
        if (!filePath) {
            return
        }

        const file = files.find((f) => f.path === filePath)

        if (!file) {
            return
        }

        if (file.directory || !file.isFile) {
            navigate('/files/' + file.name, { state: { file: file } })
        } else {
            //To navigate to file we have to navigate to a directory where this file is placed
            //So we have to find its parent and navigate there
            const splitedPath = file.path?.split('\\')

            const parent = splitedPath?.[splitedPath?.length - 2] as string

            //Finding all possible parents
            const parentsInfo = await SearchApi.search(window.btoa(parent))

            //Finding parent
            const parentInfo = parentsInfo?.find(
                (elem) => elem.path === splitedPath?.slice(0, -1).join('\\')
            )

            dispatch(
                setPaths([routePaths[0], { path: parentInfo?.name, directoryInfo: parentInfo }])
            )

            navigate('/files/' + parentInfo?.name, { state: { file: parentInfo } })
        }

        setFiles([])
    }

    const handleThumbnailClick = (filename: string) => {
        navigate('/file/' + filename)
    }

    const handleInputKeyDown = (e: KeyboardEvent) => {
        if (e.code === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            handleSearch()
        }
    }

    const handleSearchTypeSwitch = (check: boolean) => {
        if (check) {
            setSearchType('global')
        } else {
            setSearchType('directory')
        }
    }

    return (
        <Space>
            <Select
                className={styles.input}
                suffixIcon={
                    loading ? undefined : (
                        <Button
                            className={styles.search}
                            icon={<SearchOutlined />}
                            onClick={handleSearch}
                        />
                    )
                }
                placeholder='Пошук'
                filterOption={false}
                showSearch
                loading={loading}
                notFoundContent={loading ? <Spin size='small' /> : null}
                autoClearSearchValue
                value={''}
                searchValue={value}
                onSearch={setValue}
                onInputKeyDown={handleInputKeyDown}
                onSelect={handleSelect}
                listHeight={400}
            >
                {files.map((file) => (
                    <Option
                        key={uuidv4()}
                        value={file.path}
                    >
                        <div className={styles.file}>
                            <Space className={styles.fileInfo}>
                                {!file.isFile ? <FolderOutlined /> : <FileOutlined />}
                                {searchType === 'global' ? (
                                    <div className={styles.fileName}>
                                        {(() => {
                                            const path = `.../${file.path
                                                ?.split('\\')
                                                .splice(2)
                                                .join('/')}`

                                            return path
                                        })()}
                                    </div>
                                ) : (
                                    <div className={styles.fileName}>{file.name}</div>
                                )}
                            </Space>
                            {file.isFile && (
                                <Thumbnail
                                    fileUrl={file.name}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleThumbnailClick(file.name)
                                    }}
                                />
                            )}
                        </div>
                    </Option>
                ))}
            </Select>
            <Space>
                <Switch
                    className={styles.searchSwitch}
                    checked={searchType === 'global'}
                    onChange={handleSearchTypeSwitch}
                />
                <Space
                    className={styles.switchLabel}
                    direction='vertical'
                >
                    <span style={searchType === 'global' ? { color: '#1677ff' } : undefined}>
                        Глобальний пошук
                    </span>
                    <span style={searchType === 'directory' ? { color: '#1677ff' } : undefined}>
                        Пошук по директорії
                    </span>
                </Space>
            </Space>
        </Space>
    )
}

export default SearchFile
