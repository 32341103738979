import { FC, useState } from 'react'

import { FileAddOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Modal, Upload, UploadFile } from 'antd'
import { SavePolicy } from '../../../../hooks/useArchive'
import { ZeroedEnum } from '../../../../root/types'

interface NewFilesProps {
    onAdd: (file: { name: string; file: File; savePolicy: SavePolicy }[]) => Promise<void>
    currentDirectoryUUID: string
}

const NewFiles: FC<NewFilesProps> = ({ onAdd, currentDirectoryUUID }) => {
    const [createModal, setCreateModal] = useState(false)
    const [files, setFiles] = useState<UploadFile[]>()
    const [loading, setLoading] = useState(false)

    const handleCreate = async () => {
        if (!files?.length) {
            return
        }
        setLoading(true)
        await onAdd(
            files
                .filter((file) => file.originFileObj)
                .map((file) => ({
                    name: file.name,
                    file: file.originFileObj as File,
                    savePolicy: 'reject',
                }))
        )

        setCreateModal(false)
        setFiles(undefined)
        setLoading(false)
    }

    const handleCancel = () => {
        setFiles(undefined)
        setCreateModal(false)
    }

    return (
        <>
            <Button
                icon={<FileAddOutlined />}
                onClick={() => setCreateModal(true)}
                disabled={currentDirectoryUUID === ZeroedEnum['zeroedUUID']}
            >
                Додати файли
            </Button>
            <Modal
                open={createModal}
                title='Додати файли'
                onCancel={handleCancel}
                onOk={handleCreate}
                okButtonProps={{ loading }}
                okText='Зберегти'
                cancelText='Скасувати'
            >
                <Upload
                    fileList={files}
                    multiple
                    customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                            onSuccess?.('ok')
                        }, 0)
                    }}
                    onChange={({ fileList }) => {
                        setFiles(fileList.map((file) => ({ ...file, status: 'done' })))
                    }}
                >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Modal>
        </>
    )
}

export default NewFiles
