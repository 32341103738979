import { useEffect, useState } from 'react'
import { Button, Modal, notification, Space } from 'antd'
import { FilesApi } from '../api/files'
import { getFilenameName } from '../utils/getFilenameName'

export interface IFile {
    fsObjectUUID: string
    name: string
    directory: boolean
    dateModified: string
    size: string
    isFile?: boolean
    contents: IFile[]
    path?: string
}

const { confirm } = Modal

export type SavePolicy = 'reject' | 'rename' | 'replace'

const NAME_IS_RESERVED = "Файл з таким ім'ям вже існує!"
const NAME_IS_RESERVED_RENAME = 'Неможливо змінити назву файлу. Файл з такою назвою вже існує!'

const useArchive = (directoryUUID: string, fileUUID?: string) => {
    const [files, setFiles] = useState<IFile[]>([])
    const [currentDirectoryUUID, setCurrentDirectoryUUID] = useState('')

    const [getFilesLoading, setGetFilesLoading] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [copyFile, setCopyFile] = useState<IFile>()

    useEffect(() => {
        ;(async () => {
            try {
                setGetFilesLoading(true)
                const allFiles = await FilesApi.getDirectoryFiles(fileUUID as string)
                setCurrentDirectoryUUID(directoryUUID)
                setFiles(Array.isArray(allFiles) ? allFiles : allFiles?.contents)
            } catch (e) {
                console.log(e)
            } finally {
                setGetFilesLoading(false)
            }
        })()
    }, [fileUUID])

    const createDirectory = async (
        name: string
    ): Promise<{
        status: 'error' | 'success'
        message?: string
    }> => {
        try {
            const directory = await FilesApi.createDirectory(currentDirectoryUUID, name)

            notification.success({
                message: `Каталог ${getFilenameName(name)} успішно створений`,
            })

            setFiles((prev) => [...prev, directory])

            return {
                status: 'success',
            }
        } catch (e: any) {
            console.log(e.message)
            return {
                status: 'error',
                message: e.message,
            }
        }
    }

    const addFiles = async (files: { file: File; name: string; savePolicy: SavePolicy }[]) => {
        try {
            const res = await Promise.allSettled(
                files.map((fileData) => {
                    return FilesApi.createFile(
                        currentDirectoryUUID,
                        window.btoa(fileData.name),
                        fileData.file,
                        fileData.savePolicy
                    )
                })
            )

            // change save policy of rejected files
            const rejected = res.filter((item) => item.status === 'rejected')

            if (rejected.length) {
                const withReservedNames: any = []

                for (let i = 0; i < rejected.length; i++) {
                    const item = rejected[i]
                    const message = (item as PromiseRejectedResult).reason.message
                    if (message === NAME_IS_RESERVED) {
                        const savePolicy = await setSavePolicy(
                            {
                                reject: true,
                                replace: true,
                                rename: true,
                            },
                            `Файл з ім'ям "${files[i].file.name}" вже існує!`
                        )
                        if (savePolicy !== 'reject') {
                            withReservedNames.push({
                                name: files[i].name,
                                file: files[i].file,
                                savePolicy,
                            })
                        }
                    } else {
                        notification.error(message)
                    }
                }

                if (withReservedNames.length) {
                    await addFiles(withReservedNames)
                }
            }

            // clear all files with same names (in case of 'replace' policy)
            const resolvedFiles = res
                .filter((item) => item.status === 'fulfilled')
                .map((item) => (item as PromiseFulfilledResult<IFile>).value)

            setFiles((prev) =>
                prev.filter((f) => !resolvedFiles.some((file) => file.name === f.name))
            )

            resolvedFiles.forEach((file) =>
                notification.success({
                    message: `Зображення ${file.name} успішно завантажене`,
                })
            )

            // add files
            setFiles((prev) => [...prev, ...resolvedFiles])
        } catch (e: any) {
            console.log(e.message)
        }
    }

    const rename = async (fileUUID: string, destinationUUID: string, destinationName: string) => {
        try {
            setEditLoading(true)
            await FilesApi.rename(fileUUID, destinationUUID, destinationName)
            await FilesApi.systemCheck(fileUUID)

            setFiles((prev) =>
                prev.map((content) => {
                    if (content.fsObjectUUID === fileUUID) {
                        return { ...content, name: getFilenameName(destinationName) }
                    }
                    return content
                })
            )
        } catch (e: any) {
            console.log(e.message)
            notification.error({
                message: e.message,
            })
        } finally {
            setEditLoading(false)
        }
    }

    //TODO:
    const replace = async (
        fileUUID: string,
        destinationUUID: string,
        savePolicy: Exclude<SavePolicy, 'rename'> = 'reject'
    ) => {
        try {
            setEditLoading(true)
            await FilesApi.replace(fileUUID, destinationUUID, savePolicy)
            setFiles((prev) => prev.filter((f) => f.fsObjectUUID !== fileUUID))
        } catch (e: any) {
            console.log(e.message)
            if (e.message === NAME_IS_RESERVED) {
                const savePolicy = await setSavePolicy({ reject: true, replace: true })
                if (savePolicy === 'replace') {
                    replace(fileUUID, destinationUUID, 'replace')
                }
            }
        } finally {
            setEditLoading(false)
        }
    }

    const copyFileWithRename = async (fileUUID: string, fileName: string) => {
        try {
            if (!copyFile?.fsObjectUUID) {
                return
            }

            const file = await FilesApi.copy(
                fileUUID,
                currentDirectoryUUID,
                window.btoa(fileName),
                'rename'
            )
            await FilesApi.systemCheck(fileUUID)

            setFiles((prev) => [...prev, file])

            setCopyFile(undefined)
        } catch (e: any) {
            notification.error({
                message: e.message,
            })
        }
    }

    const copy = async (savePolicy: Exclude<SavePolicy, 'rename'> = 'reject') => {
        if (!copyFile?.fsObjectUUID) {
            return
        }

        try {
            setEditLoading(true)
            if (files.some((elem) => elem.fsObjectUUID === copyFile.fsObjectUUID)) {
                copyFileWithRename(copyFile.fsObjectUUID, copyFile.name)
                return
            }
            await FilesApi.copy(
                copyFile.fsObjectUUID,
                currentDirectoryUUID,
                window.btoa(copyFile.name),
                savePolicy
            )
            await FilesApi.systemCheck(copyFile?.fsObjectUUID)

            setFiles((prev) => [...prev, copyFile])

            setCopyFile(undefined)
        } catch (e: any) {
            console.log(e.message)
            if (e.message === NAME_IS_RESERVED_RENAME || e.message === NAME_IS_RESERVED) {
                const savePolicy = await setSavePolicy({
                    reject: true,
                    replace: true,
                    rename: true,
                })
                if (savePolicy === 'replace') {
                    copy('replace')
                } else if (savePolicy === 'rename') {
                    copyFileWithRename(copyFile.fsObjectUUID, copyFile.name)
                } else {
                    setCopyFile(undefined)
                }
            } else {
                notification.error({
                    message: e.message,
                })
            }
        } finally {
            setEditLoading(false)
        }
    }

    const setSavePolicy = (
        policies: { rename?: boolean; replace?: boolean; reject?: boolean } = {
            rename: false,
            replace: false,
            reject: false,
        },
        title: string = NAME_IS_RESERVED_RENAME
    ): Promise<SavePolicy> => {
        return new Promise((res) => {
            const { destroy } = confirm({
                width: 600,
                title,
                onCancel: () => {
                    res('reject')
                    destroy()
                },
                footer: (
                    <Space style={{ marginTop: 10, width: '100%', justifyContent: 'end' }}>
                        {policies.rename && (
                            <Button
                                onClick={() => {
                                    res('rename')
                                    destroy()
                                }}
                            >
                                Зберегти під новим ім'ям
                            </Button>
                        )}
                        {policies.replace && (
                            <Button
                                onClick={() => {
                                    res('replace')
                                    destroy()
                                }}
                            >
                                Замінити
                            </Button>
                        )}
                        {policies.reject && (
                            <Button
                                onClick={() => {
                                    res('reject')
                                    destroy()
                                }}
                            >
                                Відмовитися від збереження
                            </Button>
                        )}
                    </Space>
                ),
            })
        })
    }

    const deleteFile = async (fileUUID: string, name: string, isDirectory: boolean) => {
        try {
            setDeleteLoading(true)
            await FilesApi.delete(fileUUID)

            setFiles((prev) => prev.filter((elem) => elem.fsObjectUUID !== fileUUID))

            notification.success({
                message: isDirectory
                    ? `Каталог ${name} успішно видалений`
                    : `Зображення ${name} успішно видалене`,
            })
        } catch (e: any) {
            console.log(e.message)
        } finally {
            setDeleteLoading(false)
        }
    }

    return {
        files,
        currentDirectoryUUID,
        getFilesLoading,
        addFiles,
        rename,
        replace,
        copyFile,
        setCopyFile,
        copy,
        editLoading,
        deleteFile,
        deleteLoading,
        createDirectory,
    }
}

export default useArchive
