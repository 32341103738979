import { FC } from 'react'
import dayjs from 'dayjs'

import { IFile } from '../../../../hooks/useArchive'

interface TooltipContentProps {
    file: IFile
}

const TooltipContent: FC<TooltipContentProps> = ({ file }) => {
    return (
        <>
            <div>
                Дата модифікації: {dayjs(new Date(file.dateModified)).format('DD.MM.YYYY HH:mm')}
            </div>
            <div>Назва: {file.name}</div>
            {!file.directory && <div>Розмір: {file.size}</div>}
        </>
    )
}
export default TooltipContent
