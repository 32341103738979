import React, { useEffect, useState } from 'react'
import { User } from '../root/types'
import { UsersApi } from '../api/users'

const useUsers = () => {
    const [users, setUsers] = useState<User[]>()
    const [usersLoading, setUsersLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            try {
                setUsersLoading(true)
                const allUsers = await UsersApi.getUsers()
                setUsers(allUsers)
            } catch (e) {
                console.log(e)
            } finally {
                setUsersLoading(false)
            }
        })()
    }, [])
    return {
        users,
        usersLoading,
    }
}

export default useUsers
