import makeRequest from './makeRequest'
import { apiBaseurl } from './baseurl'
import { User, ZeroedEnum } from '../root/types'

export const UsersApi = {
    getUsers: async (): Promise<User[]> => {
        const { data } = await makeRequest<User[]>({
            url: `${apiBaseurl}/users/${ZeroedEnum['zeroedUUID']}`,
        })

        return data
    },
}
