import { useEffect, useState } from 'react'
import { Permission } from '../root/types'
import { PermissionsApi } from '../api/permissions'

const useAllPermissions = () => {
    const [permissions, setPermissions] = useState<Permission[]>([])
    const [permLoading, setPermLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            try {
                setPermLoading(true)
                const permissions = await PermissionsApi.getAllPermissions()
                setPermissions(permissions)
            } catch (e) {
                console.log(e)
            } finally {
                setPermLoading(false)
            }
        })()
    }, [])
    return {
        permissions,
        permLoading,
    }
}

export default useAllPermissions
