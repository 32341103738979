import { useEffect, useState } from 'react'
import { ObjPermission } from '../root/types'
import { PermissionsOnObjectApi, UpdateObjPermPayload } from '../api/permissionsOnObject'
import { notification } from 'antd'

const useObjPermissions = (fileUUID: string) => {
    const [objPermissions, setObjPermissions] = useState<ObjPermission[]>([])
    const [objPermLoading, setObjPermLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            try {
                setObjPermLoading(true)
                const permissions = await PermissionsOnObjectApi.getObjPermissions(fileUUID)
                setObjPermissions(permissions)
            } catch (e) {
                console.log(e)
            } finally {
                setObjPermLoading(false)
            }
        })()
    }, [])

    const objPermissionsUpdate = async (fileUUID: string, payload: UpdateObjPermPayload[]) => {
        try {
            setUpdateLoading(true)
            const response = await PermissionsOnObjectApi.updateObjPermissions(fileUUID, payload)

            notification.success({
                //@ts-ignore
                message: response,
            })
        } catch (e) {
            console.log(e)
            notification.error({
                message: e as string,
            })
        } finally {
            setUpdateLoading(false)
        }
    }

    return {
        objPermissions,
        objPermLoading,
        setObjPermissions,
        objPermissionsUpdate,
        updateLoading,
    }
}

export default useObjPermissions
