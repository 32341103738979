import { createSlice } from '@reduxjs/toolkit'
import { IFile } from '../hooks/useArchive'

interface InitialStateProps {
    paths: {
        path: string
        directoryInfo: IFile | undefined
    }[]
}

const initialState: InitialStateProps = {
    paths: [{ path: 'Home', directoryInfo: undefined }],
}

const rootPathSlice = createSlice({
    name: 'navPaths',
    initialState,
    reducers: {
        setPaths: (state, action) => {
            state.paths = action.payload
        },
    },
})

export const { setPaths } = rootPathSlice.actions

export default rootPathSlice.reducer
