import { useLocation } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { Button, Select, Space } from 'antd'
import { ReconciliationOutlined } from '@ant-design/icons'

import styles from './Archive.module.scss'

import useArchive, { IFile } from '../../hooks/useArchive'
import { Files, SearchFile } from '../../components'
import useSortFiles from './useSortFiles'
import { NewDirectory, NewFiles, PathItem } from './components'
import { ZeroedEnum } from '../../root/types'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useEffect } from 'react'
import { setPaths } from '../../store/rootPathsSlice'
import Loader from '../../components/Loader'

const { Option } = Select

const Archive = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()

    // Directory info from navigate('/files/' + file.name, { state: { file: file } })
    const openedDirectory = location.state?.file as IFile

    const routePaths = useAppSelector((state) => state.rootPathsSlice.paths)

    const {
        files,
        currentDirectoryUUID,
        getFilesLoading,
        createDirectory,
        addFiles,
        rename,
        replace,
        copyFile,
        setCopyFile,
        copy,
        deleteFile,
    } = useArchive(
        openedDirectory?.fsObjectUUID ? openedDirectory?.fsObjectUUID : ZeroedEnum['zeroedUUID'],
        openedDirectory?.fsObjectUUID ? openedDirectory?.fsObjectUUID : ZeroedEnum['zeroedUUID']
    )

    const { sortedFiles, setSort } = useSortFiles(files)

    // Fill paths
    useEffect(() => {
        if (
            openedDirectory &&
            !routePaths.map((route) => route.path).includes(openedDirectory?.name)
        ) {
            openedDirectory?.name !== undefined &&
                dispatch(
                    setPaths([
                        ...routePaths,
                        { path: openedDirectory?.name, directoryInfo: openedDirectory },
                    ])
                )
        } else {
            const findRouteIndex =
                openedDirectory === undefined
                    ? 0
                    : routePaths.findIndex((elem) => elem.path === openedDirectory?.name)
            if (findRouteIndex !== routePaths.length + 1) {
                const path = routePaths.slice(0, findRouteIndex + 1)
                dispatch(setPaths(path))
            }
        }
    }, [openedDirectory])

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <SearchFile currentDirectoryFiles={files} />
                <Space className={styles.actions}>
                    <Select
                        className={styles.sort}
                        placeholder='Сортування'
                        allowClear
                        onSelect={setSort}
                        onClear={() => setSort(undefined)}
                    >
                        <Option value='old'>Від старіших</Option>
                        <Option value='new'>Від новіших</Option>
                        <Option value='alphabet'>По імені</Option>
                    </Select>
                    <NewDirectory onAdd={createDirectory} />
                    <NewFiles
                        onAdd={addFiles}
                        currentDirectoryUUID={currentDirectoryUUID}
                    />
                    {copyFile && (
                        <Button
                            icon={<ReconciliationOutlined />}
                            onClick={() => copy()}
                        >
                            Вставити
                        </Button>
                    )}
                </Space>
                <span style={{ fontSize: 16 }}>
                    {routePaths.map((route) => (
                        <PathItem
                            key={route.path}
                            path={route.path}
                            directoryInfo={route?.directoryInfo}
                        />
                    ))}
                </span>
                {getFilesLoading ? (
                    <Loader />
                ) : (
                    <Files
                        files={sortedFiles}
                        onRename={rename}
                        onReplace={replace}
                        setCopyFile={setCopyFile}
                        onAdd={addFiles}
                        onDelete={deleteFile}
                        currentDirectoryUUID={currentDirectoryUUID}
                    />
                )}
            </div>
        </DndProvider>
    )
}

export default Archive
