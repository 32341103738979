import { Button, Form, Modal, Table, notification } from 'antd'
import useAnonymAccess from '../../../../hooks/useAnonymAccess'
import { SharedToken } from '../../../../api/anonymAccess'
import { useState } from 'react'
import { EditableCell } from './components/EditableCell'
import AnonymAccessActions from './components/AnonymAccessActions'

interface AnonymAccessModalProps {
    open: boolean
    onCancel: () => void
    setAnonymAccessModal: (open: boolean) => void
    fileUUID: string
}

const AnonymAccessModal = ({
    open,
    onCancel,
    setAnonymAccessModal,
    fileUUID,
}: AnonymAccessModalProps) => {
    const [form] = Form.useForm()

    const {
        anonymAccess,
        anonymAccessLoading,
        sharedToken,
        setSharedToken,
        createAnonymAccess,
        deleteLoading,
        deleteAnonymAccess,
        updateAnonymAccess,
    } = useAnonymAccess(fileUUID)

    const [editingKey, setEditingKey] = useState('')

    const isEditing = (record: SharedToken) => record.sharedTokenUUID === editingKey

    //On Edit click
    const edit = (record: Partial<SharedToken>) => {
        if (!record.sharedTokenUUID) {
            return
        }

        form.setFieldsValue({ description: '', ...record })
        setEditingKey(record.sharedTokenUUID)
    }

    //Save feature
    const save = async (record: Partial<SharedToken>) => {
        try {
            const description = await form.getFieldValue('description').trim()

            const newData = [...sharedToken]
            let index
            if (!record.sharedTokenUUID) {
                index = newData.length - 1
            } else {
                index = newData.findIndex((item) => record.sharedTokenUUID === item.sharedTokenUUID)
            }

            if (description.length) {
                if (!record.sharedTokenUUID) {
                    await createAnonymAccess(fileUUID, description)

                    setEditingKey('')
                } else {
                    await updateAnonymAccess(record.sharedTokenUUID, description)
                    setEditingKey('')
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    // On Cancel click
    const cancel = (record: SharedToken) => {
        if (record.sharedTokenUUID) {
            setEditingKey('')
        } else {
            setSharedToken(sharedToken.slice(0, -1))
        }
    }

    //Add new access
    const handleAccessAdd = () => {
        setSharedToken((prev) => [
            ...prev,
            {
                url: '',
                sharedTokenUUID: '',
                dateCreated: '',
                description: '',
            },
        ])
        setEditingKey('')
    }

    //Copy access
    const handleCopyAccess = (record: SharedToken) => {
        navigator.clipboard.writeText(record.url)
        notification.success({
            message: `Посилання скопійовано`,
        })
    }

    const itemsColumns = [
        {
            width: '30%',
            title: `Примітка`,
            dataIndex: 'description',
            key: 'description',
            editable: true,
        },
        {
            width: '50%',
            title: `Опис`,
            dataIndex: 'url',
            key: 'url',
        },
        {
            dataIndex: 'operation',
            width: '20%',
            render: (_: any, record: SharedToken) => {
                return (
                    <AnonymAccessActions
                        editable={record.sharedTokenUUID ? isEditing(record) : true}
                        handleDelete={() => deleteAnonymAccess(record.sharedTokenUUID)}
                        deleteLoading={deleteLoading}
                        save={() => save(record)}
                        edit={() => edit(record)}
                        cancel={() => cancel(record)}
                        handleCopyAccess={() => handleCopyAccess(record)}
                        editingKey={editingKey}
                    />
                )
            },
        },
    ]

    const mergedColumns = itemsColumns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record: SharedToken) => ({
                record,
                dataIndex: col.dataIndex,
                title: record.description,
                editing: isEditing(record),
            }),
        }
    })

    return (
        <Form
            form={form}
            component={false}
        >
            <Modal
                width={1000}
                open={open}
                onCancel={onCancel}
                title={<div style={{ marginBottom: 30 }}></div>}
                maskClosable={false}
                footer={[
                    <Button
                        key='ok'
                        type='primary'
                        onClick={onCancel}
                        style={{ width: 80 }}
                    >
                        OK
                    </Button>,
                ]}
            >
                <>
                    <Table
                        rowClassName='editable-row'
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        columns={mergedColumns}
                        dataSource={sharedToken}
                        loading={anonymAccessLoading}
                        pagination={false}
                        rowKey={(item) => item.sharedTokenUUID}
                    />
                    <Button
                        onClick={() => handleAccessAdd()}
                        type='primary'
                        style={{
                            marginTop: 16,
                        }}
                    >
                        Додати доступ
                    </Button>
                </>
            </Modal>
        </Form>
    )
}

export default AnonymAccessModal
