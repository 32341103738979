import { useEffect, useState } from 'react'
import { AnonymAccess, AnonymAccessApi, SharedToken } from '../api/anonymAccess'

const initialAnonymAccess: AnonymAccess = {
    fsObjectUUID: '',
    name: '',
    sharedTokens: [],
}

const useAnonymAccess = (fileUUID: string) => {
    const [anonymAccess, setAnonymAccess] = useState<AnonymAccess>(initialAnonymAccess)
    const [anonymAccessLoading, setAnonymAccessLoading] = useState(false)
    const [sharedToken, setSharedToken] = useState<SharedToken[]>([])
    const [isFetch, setIsFetch] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            try {
                setAnonymAccessLoading(true)
                const access = await AnonymAccessApi.getAnonymAccessInfo(fileUUID)
                setAnonymAccess(access)
                setSharedToken(access.sharedTokens)
            } catch (e) {
                console.log(e)
            } finally {
                setAnonymAccessLoading(false)
                setIsFetch(false)
            }
        })()
    }, [isFetch])

    const createAnonymAccess = async (
        fileUUID: string,
        comment: string
    ): Promise<{
        status: 'error' | 'success'
        message?: string
    }> => {
        try {
            await AnonymAccessApi.createAnonymAccess(fileUUID, comment)

            setIsFetch(true)

            return {
                status: 'success',
            }
        } catch (e: any) {
            console.log(e.message)
            return {
                status: 'error',
                message: e.message,
            }
        }
    }
    const updateAnonymAccess = async (
        sharedFileUUID: string,
        comment: string
    ): Promise<{
        status: 'error' | 'success'
        message?: string
    }> => {
        try {
            await AnonymAccessApi.updateAnonymAccess(sharedFileUUID, comment)

            setIsFetch(true)

            return {
                status: 'success',
            }
        } catch (e: any) {
            console.log(e.message)
            return {
                status: 'error',
                message: e.message,
            }
        }
    }

    const deleteAnonymAccess = async (sharedFileUUID: string) => {
        try {
            setDeleteLoading(true)
            await AnonymAccessApi.deleteAnonymAccess(sharedFileUUID)
            setIsFetch(true)

            // setSharedToken((prev) => prev.filter((elem) => elem.fsObjectUUID !== fileUUID))
        } catch (e: any) {
            console.log(e.message)
        } finally {
            setDeleteLoading(false)
        }
    }

    return {
        anonymAccess,
        anonymAccessLoading,
        sharedToken,
        setSharedToken,
        createAnonymAccess,
        deleteAnonymAccess,
        deleteLoading,
        updateAnonymAccess,
    }
}

export default useAnonymAccess
