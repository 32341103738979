import { FC, useState } from 'react'

import { FolderAddOutlined } from '@ant-design/icons'
import { Alert, Button, Input, Modal } from 'antd'

interface NewDirectoryProps {
    onAdd: (name: string) => Promise<{
        status: 'error' | 'success'
        message?: string
    }>
}

const NewDirectory: FC<NewDirectoryProps> = ({ onAdd }) => {
    const [createModal, setCreateModal] = useState(false)
    const [value, setValue] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleCreate = async () => {
        setError('')
        setLoading(true)
        const response = await onAdd(window.btoa(value))
        if (response.status === 'success') {
            setCreateModal(false)
        } else {
            setError(response.message || '')
        }
        setLoading(false)
    }

    const handleCancel = () => {
        setCreateModal(false)
        setError('')
    }

    return (
        <>
            <Button
                icon={<FolderAddOutlined />}
                onClick={() => setCreateModal(true)}
            >
                Нова папка
            </Button>
            <Modal
                open={createModal}
                title='Нова папка'
                onCancel={handleCancel}
                onOk={handleCreate}
                okButtonProps={{ loading }}
                okText='Зберегти'
                cancelText='Скасувати'
            >
                <Input
                    placeholder='Назва'
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
                {!!error && (
                    <Alert
                        style={{ marginTop: 10 }}
                        message={error}
                        type='error'
                    />
                )}
            </Modal>
        </>
    )
}

export default NewDirectory
