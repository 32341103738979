import { List, Modal } from 'antd'
import { useState } from 'react'
import useUsers from '../../../../../hooks/useUsers'
import { ObjPermission, User } from '../../../../../root/types'

interface NewUserModalProps {
    open: boolean
    onCancel: () => void
    handleUserAdd: (user: User) => void
    objPermissions: ObjPermission[]
}

const NewUserModal = ({ open, onCancel, handleUserAdd, objPermissions }: NewUserModalProps) => {
    const { users, usersLoading } = useUsers()

    const filteredUserArray = users?.filter(
        (user) => !objPermissions.some((permission) => permission.user.userUUID === user.userUUID)
    )

    const [selectedUser, setSelectedUser] = useState<User>()

    return (
        <Modal
            width={600}
            open={open}
            onCancel={onCancel}
            okText={'Додати'}
            onOk={() => handleUserAdd(selectedUser as User)}
            cancelText={'Скасувати'}
            title={<div style={{ marginBottom: 30 }}>Виберіть нового користувача</div>}
        >
            <List
                loading={usersLoading}
                dataSource={filteredUserArray}
                renderItem={(item) => (
                    <List.Item
                        onClick={() => setSelectedUser(item)}
                        style={{
                            borderRadius: 6,
                            paddingLeft: 10,
                            backgroundColor:
                                selectedUser?.userUUID === item.userUUID
                                    ? '#31a84a'
                                    : 'transparent',
                            color: selectedUser?.userUUID === item.userUUID ? '#fff' : '#000',
                        }}
                    >
                        {item.name}
                    </List.Item>
                )}
            />
        </Modal>
    )
}

export default NewUserModal
