import makeRequest from './makeRequest'
import { apiBaseurl } from './baseurl'
import { IFile, SavePolicy } from '../hooks/useArchive'

export const FilesApi = {
    systemCheck: async (fileUUID: string): Promise<any> => {
        const { data } = await makeRequest<any>({
            url: `${apiBaseurl}/fsconsistency/${fileUUID}/`,
        })
        return data
    },

    getDirectoryFiles: async (fileUUID: string): Promise<IFile[] | IFile> => {
        const { data } = await makeRequest<IFile[] | IFile>({
            url: `${apiBaseurl}/filesystem/${fileUUID}/`,
        })
        return data
    },

    getFiles: async (fileUUID: string): Promise<IFile[]> => {
        const { data } = await makeRequest<IFile[]>({
            url: `${apiBaseurl}/files/${fileUUID}/`,
        })
        return data
    },

    createDirectory: async (fileUUID: string, fileName: string): Promise<IFile> => {
        const { data } = await makeRequest<IFile>({
            method: 'post',
            url: `${apiBaseurl}/files/${fileUUID}/`,
            params: {
                name: fileName,
                'save-policy': 'reject',
                isFile: false,
            },
        })
        return data
    },

    createFile: async (
        fileUUID: string,
        filename: string,
        file: File,
        savePolicy: SavePolicy = 'reject'
    ): Promise<IFile> => {
        const { data } = await makeRequest<IFile>({
            method: 'post',
            url: `${apiBaseurl}/files/${fileUUID}/`,
            params: {
                name: filename,
                'save-policy': savePolicy,
                isFile: true,
            },
            data: file,
            headers: {
                'Content-Type': file.type,
            },
        })
        return data
    },

    editFile: async (fileUUID: string, fileName: string, file: File): Promise<IFile> => {
        const { data } = await makeRequest<IFile>({
            method: 'post',
            url: `${apiBaseurl}/files/${fileUUID}/`,
            params: {
                name: fileName,
                'save-policy': 'replace',
                isFile: true,
            },
            data: file,
            headers: {
                'Content-Type': file.type,
            },
        })
        return data
    },

    copy: async (
        fileUUID: string,
        destinationUUID: string,
        destinationName: string,
        savePolicy: SavePolicy
    ): Promise<IFile> => {
        const { data } = await makeRequest<IFile>({
            method: 'put',
            url: `${apiBaseurl}/files/${fileUUID}/`,
            params: {
                destinationUUID,
                destinationName,
                'save-policy': savePolicy,
                action: 'copy',
            },
        })

        return data
    },

    replace: async (
        fileUUID: string,
        destinationUUID: string,
        savePolicy: Exclude<SavePolicy, 'rename'>
    ): Promise<void> => {
        await makeRequest<IFile>({
            method: 'put',
            url: `${apiBaseurl}/files/${fileUUID}/`,
            params: {
                destinationUUID,
                'save-policy': savePolicy,
                action: 'move',
            },
        })
    },

    rename: async (
        fileUUID: string,
        destinationUUID: string,
        destinationName: string
    ): Promise<IFile> => {
        const { data } = await makeRequest<IFile>({
            method: 'put',
            url: `${apiBaseurl}/files/${fileUUID}/`,
            params: {
                destinationName,
                destinationUUID,
                'save-policy': 'reject',
                action: 'rename',
            },
        })

        return data
    },

    delete: async (fileUUID: string): Promise<void> => {
        await makeRequest({
            method: 'delete',
            url: `${apiBaseurl}/files/${fileUUID}/`,
        })
    },
}
