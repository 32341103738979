import { Spin } from 'antd'

const Loader = () => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 100,
            }}
        >
            <Spin size='large' />
        </div>
    )
}

export default Loader
