import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useDrop } from 'react-dnd'
import { IFile } from '../../../../hooks/useArchive'

interface PathItemProps {
    path: string
    directoryInfo: IFile | undefined
}

const PathItem: FC<PathItemProps> = ({ path, directoryInfo }) => {
    const [, drop] = useDrop(() => ({
        accept: 'type 1',
        drop: () => ({ name: path }),
    }))

    return (
        <>
            <Link
                ref={drop}
                to={path === 'Home' ? '/' : `/files/${path}`}
                state={{ file: directoryInfo }}
            >
                &nbsp;{path}&nbsp;
            </Link>
            /
        </>
    )
}

export default PathItem
