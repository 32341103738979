import { FC, FocusEvent, useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useNavigate } from 'react-router-dom'

import { Dropdown, Image, MenuProps, notification, Space, Spin, Tooltip } from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    FileOutlined,
    FolderOutlined,
    CopyOutlined,
    DownloadOutlined,
    StopOutlined,
    FileProtectOutlined,
    FolderViewOutlined,
} from '@ant-design/icons'

import styles from './File.module.scss'

import { IFile } from '../../hooks/useArchive'
import { isImageFileName } from '../../utils/isImageFileName'
import { DeleteModal, TooltipContent } from './components'
import { FileApi } from '../../api/file'
import { downloadFile } from '../../utils/downloadFile'
import AccessRightsModal from './components/AccessRightModal'
import AnonymAccessModal from './components/AnonymAccessModal'

interface FileProps {
    file: IFile
    onRename: (fileUUID: string, destinationUUID: string, destinationName: string) => void
    onReplace: (name: string, destination: string) => void
    setCopyFile: (file: IFile) => void
    onDelete: (fileUUID: string, name: string, isDirectory: boolean) => void
    currentDirectoryUUID: string
}

const File: FC<FileProps> = ({
    file,
    onDelete,
    onRename,
    setCopyFile,
    onReplace,
    currentDirectoryUUID,
}) => {
    const navigate = useNavigate()

    const [fileLoading, setFileLoading] = useState(false)

    const ref = useRef<HTMLDivElement>(null)

    const [renameMode, setRenameMode] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [accessRightsModal, setAccessRightsModal] = useState(false)
    const [anonymAccessModal, setAnonymAccessModal] = useState(false)

    const [imageUrl, setImageUrl] = useState<string>()
    const [imageBlobFullDimension, setImageBlobFullDimension] = useState<Blob>()

    const [, drop] = useDrop({
        accept: 'type 1',
        canDrop: () => file.directory || file.isFile === false,
        drop: () => ({ name: 'Dustbin' }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const [{ opacity }, drag] = useDrag(() => ({
        type: 'type 1',
        item: { name: file.name },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
        end: async (item, monitor) => {
            const dropResult: { name: string } | null = monitor.getDropResult()

            if (item && dropResult) {
                console.log(`You dropped ${item.name} into ${dropResult.name}!`)
            }

            // if (dropResult && file.fsObjectUUID !== dropResult.fileUUID) {
            //     let destination
            //     if (!dropResult.fileUUID) {
            //         destination = window.btoa(file.name)
            //     } else {
            //         destination = window.btoa(
            //             `${decodeFromBase64(dropResult.fileUUID)}\\${file.name}`
            //         )
            //     }

            //     // onReplace(file.name, destination)
            // }
        },
    }))

    useEffect(() => {
        if (file.directory || !file.isFile) {
            return
        }

        ;(async () => {
            try {
                setFileLoading(true)
                if (!file.directory || file.isFile) {
                    const fileData = await FileApi.getFile(file.fsObjectUUID, true)
                    const fileDataFullDimension = await FileApi.getFile(file.fsObjectUUID, false)

                    if (fileData.type.includes('image')) {
                        setImageUrl(URL.createObjectURL(fileData))
                        setImageBlobFullDimension(fileDataFullDimension)
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                setFileLoading(false)
            }
        })()
    }, [file])

    const handleClick = () => {
        if (!fileLoading && (file.directory !== null || file.isFile !== null)) {
            if (file.directory || !file.isFile) {
                navigate('/files/' + file.name, { state: { file: file } })
            } else {
                if (!file.directory || file.isFile) {
                    navigate('/file/' + file.name, {
                        state: {
                            currentDirectoryUUID: currentDirectoryUUID,
                            file: file,
                            imageBlob: imageBlobFullDimension,
                        },
                    })
                } else {
                    notification.info({
                        message: 'Тип файлу не підтримується для редагування',
                    })
                }
            }
        }
    }

    const handleInputBlur = async (e: FocusEvent<HTMLInputElement>) => {
        if (e.target.value === file.name) {
            setRenameMode(false)
            return
        }
        const value = e.target.value
        onRename(file.fsObjectUUID, currentDirectoryUUID, window.btoa(value))
        setRenameMode(false)
    }

    const handleDownload = async () => {
        console.log(file)

        if (isImageFileName(file.name)) {
            const fileData = await FileApi.getFile(file.fsObjectUUID)
            downloadFile(URL.createObjectURL(fileData), file.name)
        }
    }

    drag(drop(ref))

    const contextItems: MenuProps['items'] = [
        {
            key: 'edit',
            label: (
                <Space>
                    <EditOutlined />
                    Переіменувати
                </Space>
            ),
            onClick: () => setRenameMode(true),
        },
        {
            key: 'copy',
            label: (
                <Space>
                    <CopyOutlined />
                    Копіювати
                </Space>
            ),
            onClick: () => setCopyFile(file),
        },
        {
            key: 'delete',
            label: (
                <Space>
                    <DeleteOutlined />
                    Видалити
                </Space>
            ),
            onClick: () => setDeleteModal(true),
        },
    ]

    if (file.isFile) {
        contextItems.splice(0, 0, {
            key: 'download',
            label: (
                <Space>
                    <DownloadOutlined />
                    Завантажити
                </Space>
            ),
            onClick: handleDownload,
        })
    }

    if (file.directory || !file.isFile) {
        contextItems.splice(3, 0, {
            key: 'accessRights',
            label: (
                <Space>
                    <FolderViewOutlined />
                    Права доступу
                </Space>
            ),
            onClick: () => setAccessRightsModal(true),
        })
        contextItems.splice(4, 0, {
            key: 'anonymAccess',
            label: (
                <Space>
                    <FileProtectOutlined />
                    Анонімний доступ
                </Space>
            ),
            onClick: () => setAnonymAccessModal(true),
        })
    }

    return (
        <>
            <Tooltip title={<TooltipContent file={file} />}>
                <Dropdown
                    menu={{ items: contextItems }}
                    trigger={['contextMenu']}
                >
                    <div
                        //@ts-ignore
                        ref={drag(drop(ref))}
                        style={{ opacity }}
                        className={styles.file}
                        onClick={handleClick}
                    >
                        <div className={styles.preview_container}>
                            {fileLoading ? (
                                <Spin size='large' />
                            ) : file.directory === null || file.isFile === null ? (
                                <StopOutlined className={styles.icon} />
                            ) : file.directory || !file.isFile ? (
                                <FolderOutlined className={styles.icon} />
                            ) : imageUrl ? (
                                <div>
                                    <Image
                                        src={imageUrl}
                                        alt=''
                                        className={styles.preview_image}
                                    />
                                </div>
                            ) : (
                                <FileOutlined className={styles.icon} />
                            )}
                        </div>
                        {renameMode ? (
                            <div style={{ height: '30%' }}>
                                <input
                                    onClick={(e) => e.stopPropagation()}
                                    onDrag={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    onMouseMove={(e) => {
                                        e.stopPropagation()
                                    }}
                                    style={{ width: '100%', zIndex: 10 }}
                                    autoFocus
                                    defaultValue={file.name}
                                    onBlur={handleInputBlur}
                                />
                            </div>
                        ) : (
                            <div className={styles.name}>{file.name}</div>
                        )}
                    </div>
                </Dropdown>
            </Tooltip>
            {deleteModal && (
                <DeleteModal
                    open={deleteModal}
                    onDelete={() => onDelete(file.fsObjectUUID, file.name, file.directory)}
                    onCancel={() => setDeleteModal(false)}
                />
            )}
            {accessRightsModal && (
                <AccessRightsModal
                    open={accessRightsModal}
                    onCancel={() => setAccessRightsModal(false)}
                    fileUUID={file.fsObjectUUID}
                    setAccessRightsModal={setAccessRightsModal}
                />
            )}
            {anonymAccessModal && (
                <AnonymAccessModal
                    open={anonymAccessModal}
                    onCancel={() => setAnonymAccessModal(false)}
                    setAnonymAccessModal={setAnonymAccessModal}
                    fileUUID={file.fsObjectUUID}
                />
            )}
        </>
    )
}

export default File
