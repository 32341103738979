import { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Space } from 'antd'
import { FilesApi } from '../../api/files'
import { ImageEditor, ImageViewer } from '../../components'
import { base64toFile } from '../../utils/base64imageToFile'
import { isMobile } from '../../utils/isMobile'
import { IFile } from '../../hooks/useArchive'

const FileEditor: FC = () => {
    const navigate = useNavigate()

    const location = useLocation()

    const imageBlob = location.state?.imageBlob

    const imageUrl = URL.createObjectURL(imageBlob)

    const currentDirectoryUUID = location.state?.currentDirectoryUUID
    const currentFile = location.state?.file as IFile

    const [loading, setLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const handleSave = async (fileBase64: string) => {
        const file = base64toFile(
            fileBase64.slice(22),
            fileBase64.slice(5, 14),
            currentFile?.name || ''
        )

        try {
            setLoading(true)
            await FilesApi.editFile(currentDirectoryUUID, window.btoa(currentFile?.name), file)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            {imageUrl &&
                (isMobile() ? (
                    <Space
                        style={{ width: '100%', height: '100%' }}
                        direction='vertical'
                    >
                        <Button onClick={handleBack}>Назад</Button>
                        <img
                            width='100%'
                            src={imageUrl}
                            alt=''
                        />
                    </Space>
                ) : isEditing ? (
                    <ImageEditor
                        fileUrl={imageUrl}
                        loading={loading}
                        onSave={handleSave}
                        onBack={handleBack}
                    />
                ) : (
                    <ImageViewer
                        fileUrl={imageUrl}
                        onBack={handleBack}
                        onEdit={() => setIsEditing(true)}
                    />
                ))}
        </>
    )
}

export default FileEditor
