import makeRequest from './makeRequest'
import { apiBaseurl } from './baseurl'
import { Permission } from '../root/types'

export const PermissionsApi = {
    getAllPermissions: async (): Promise<Permission[]> => {
        const { data } = await makeRequest<Permission[]>({
            url: `${apiBaseurl}/access-types/`,
        })

        return data
    },
}
