import makeRequest from './makeRequest'
import { apiBaseurl } from './baseurl'
import { IFile } from '../hooks/useArchive'

export const SearchApi = {
    search: async (filename: string): Promise<IFile[]> => {
        const { data } = await makeRequest<IFile[]>({
            url: apiBaseurl + '/search/',
            params: {
                filename,
            },
        })

        // @ts-ignore
        return data as Blob
    },
}
